// UI-kit
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "@fancyapps/ui/dist/fancybox/fancybox.css";
  @import "swiper/swiper-bundle.min.css";
  @import "./ui-kit/fonts";
  @import "./ui-kit/basic";
  @import "./ui-kit/scrollbar";
  @import "./ui-kit/custom";
}

@layer components {
  @import "./ui-kit/load";
  @import "./ui-kit/icon";
  @import "./ui-kit/button-swipe";
}

@import "./ui-kit/waved";

// Frontend
@import "./frontend/main";