// Шапка документа
.header {
  transform: translateY(var(--top));
}

// Меню
.menu {
  &[data-sidebar="close"] {
    @apply invisible opacity-0;
  }

  &[data-sidebar="close"] &__content {
    transform: translateX(-100%);

    @apply invisible opacity-0;
  }

  &[data-sidebar="open"] {
    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__content {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
  }
}

// Главный блок
.main {
  &::before {
    background: linear-gradient(180deg, rgba(28, 28, 28, 0) 27.43%, #000 44.35%);

    @apply content-auto absolute z-1 inset-0;

    @screen sm {
      background-image: linear-gradient(180deg, rgba(28, 28, 28, 0.4) 0%, #000 100%);
    }
  }
}

// Серии
.series {
  &__item {

    @media (hover) {
      &:hover {
        & .series__link {
          @apply bg-white font-semibold text-black border-gray border-opacity-100;
        }

        & .series__content {
          @apply visible opacity-100;
        }
      }
    }

    &:not(:last-child) {
      @apply border-r border-solid border-white border-opacity-20;
    }
  }
}

// Каталог
.catalog {
  &__accordion[data-accordion="hidden"] &__text {
    & span:last-child {
      @apply hidden;
    }
  }

  &__accordion[data-accordion="active"] &__arrow {
    transform: rotate(-90deg);
  }

  &__accordion[data-accordion="active"] &__text {
    & span:first-child {
      @apply hidden;
    }
  }
}

// О нас
.about {
  &__card {
    background-image: linear-gradient(180deg, rgba(28, 28, 28, 0) 31.34%, rgba(28, 28, 28, 0.2) 100%);
  }
}

// Галерея
.gallery {
  &__card {
    background-image: linear-gradient(180deg, rgba(28, 28, 28, 0) 31.34%, rgba(28, 28, 28, 0.2) 100%);
  }
}

// Квиз
.quiz {
  background-image: linear-gradient(180deg, rgba(202, 110, 28, 0) 51.14%, #BE681B 96.97%);

  &-slider {
    &[data-quiz="stop"] .swiper-button-next {
      @apply opacity-50 pointer-events-none;
    }

    &[data-quiz-end] &-submit {
      @apply flex;
    }
  }
}