// Загрузчик
.loader {
  @apply flex items-center justify-center absolute inset-0 pointer-events-none;

  &-progress {
    animation: progress 1.8s linear infinite;

    @apply text-40 text-primary;
  }

  &-icon {
    animation: loader 1.5s ease-in-out infinite;
  }
}

// Загрузка
.preloader {

  &::before,
  &::after {
    border-width: 12px;

    @apply content-auto absolute inset-0 rounded-inherit border-solid border-inherit;
  }

  &::before {
    animation: progress 1s linear infinite;

    @apply border-x-transparent;
  }

  &::after {
    animation: progress 1.2s linear infinite;

    @apply border-y-transparent;
  }

  @apply flex items-center justify-center relative bg-transparent text-primary rounded-max w-24 h-24;

  &-circle {
    animation: preloader 1.2s linear infinite;

    @apply bg-current rounded-inherit w-10 h-10;
  }
}

@keyframes progress {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    stroke: {
      dasharray : 1px, 200px;
      dashoffset: 0;
    }
  }

  50% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -15px;
    }
  }

  100% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -125px;
    }
  }
}

@keyframes preloader {

  0%,
  100% {
    transform: scale(0.4);

    @apply opacity-20;
  }

  50% {
    transform: scale(1);

    @apply opacity-100;
  }
}