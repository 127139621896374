// Волна
.waved {
  @apply absolute z-1 inset-0 rounded-inherit overflow-hidden pointer-events-none;

  &-circle {
    background-color: var(--waved-color);
    transform       : translate(-50%, -50%) scale(0);
    animation       : waved 1s ease forwards;

    @apply block absolute rounded-max w-10 h-10;
  }

  [data-waved="light"] & {
    --waved-color: theme("colors.white.DEFAULT / 40%");
  }

  [data-waved="dark"] & {
    --waved-color: theme("colors.black.DEFAULT / 40%");
  }
}

@keyframes waved {
  to {
    transform: translate(-50%, -50%) scale(25);

    @apply opacity-0;
  }
}